import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
// import { parseHtml } from '@/utils/common';

interface StoresProps {
  data: any;
}

const Stores: React.FC<StoresProps> = ({ data }) => {
  return (
    <>
      <section className={styles.storesection}>
        <div className="container-fluid">
          <div className={styles.storeinner}>
            {/* <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.storeheading}>
                  <h2>{data?.title}</h2>
                  <div className={styles.storesoffer}>
                    <div className={styles.stores}>
                      <h4>{data?.offerTitle}</h4>
                    </div>
                    <div className={styles.storesright}>
                      {parseHtml(data?.offerHtml)}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.storecontent}>
              <div className={styles['store-left']}>
                <p className={styles['heading']}>
                  100+
                  <span> Experience Stores Across India</span>
                </p>
                <p className={styles['offer-box']}>
                  <span> Get Extra Upto</span>
                  <strong className={styles['strong']}>10% Off</strong>
                  <small className={styles['small']}>
                    On Shopping from Your Nearest Stores
                  </small>
                </p>
                <div className={styles.allstoresbtn}>
                  <Link
                    href="/offline-furniture-store"
                    className={`${styles['btn-primary']} btn btn-primary`}
                  >
                    {data.exploreStoresBtnName}
                  </Link>
                </div>
              </div>
              <div className={styles['store-right']}>
                <div className="wdRow wdgx-3 wdRow-cols-1 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-4">
                  {data?.items?.map((store: any, index: number) => (
                    <div className={`${styles['col-px']} wdCol`} key={index}>
                      <Link href={store.link} className={styles.storecard}>
                        <div className={styles.storeimg}>
                          <figure>
                            <Image
                              src={store.imageUrl}
                              alt="Experience Store"
                              width={445}
                              height={296}
                              quality={75}
                              loading="lazy"
                            />
                          </figure>
                          <div className={styles.storetext}>
                            <h4> {store.name}</h4>
                            <p>{store.storeCount} Stores</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stores;
